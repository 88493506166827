import * as React from 'react';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  unstable_HistoryRouter as HistoryRouter
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { history } from './app/history';
import { getLoggedInUser } from 'utilities/helpers';
import Login from './features/login';
import ForgotPassword from './features/forgot-password';
import ChangePassword from './features/change-password';
import Loader from './common/Loader';
import DashBoard from './features/dashboard';
import InitiateEnvelope1 from './features/initiate-envelope';
import InitiateEnvelope2 from './features/initiate-envelope/components/InitiateEnvelopePage2/index';
import AdminDashboard from './features/admin';
import AdminManage from './features/admin-manage';
import AdminManage1 from './features/pending';
import AdminDoaEnvelope from './features/admin-doa-envelope';
import { MsalProvider } from '@azure/msal-react';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import PageNotFound from './common/PageNotFound';
import Favourites from './features/favourites';
import DeleteEnv from './features/deletedenvelopes';
import Approver from './features/approver';
import Rejected from './features/rejected';
import Hold from './features/hold';
import Forward from './features/forward';
import ActionsRequired from './features/actions-required';
import ReviewActions from './features/review-actions';
import FinalReview from './features/final-review';
import LocalStorage from 'utilities/localStorage';
import Reports from './features/reports';
import CompletedNFAS from './features/approved-receipts';
import UnAuthReviewActions from './features/unauth-review';
import Settings from './features/settings';
import AdminSettings from './features/settings/components/Admin';
import DesignationMaster from './features/settings/components/DesignationMaster';
import DesignationMapping from './features/settings/components/DesignationMapping';
import Projects from './features/settings/components/Project';
import BU from './features/settings/components/BU';
import SBU from './features/settings/components/SBU';
import SBG from './features/settings/components/SBG';
import Result from './features/successPage';
import Cancelled from './features/cancelled';
import Information from './features/information';
import MyRejections from './features/my-rejections';
import Categories from './features/settings/components/Categories';
import EditDOAPage from './features/settings/components/DOA-Flow/EditFlow';
import SubCategories from './features/settings/components/Sub-Categories';
import DoaList from './features/settings/components/DOA-Flow';
import CreateDOAPage from './features/settings/components/DOA-Flow/AddFlow';
import DesignationsNew from './features/settings/components/DesignationNew';

const configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_URL
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    windowHashTimeout: 60000,
    iframeHashTimeout: 60000,
    loadFrameTimeout: 0,
    asyncPopups: false
  }
};

const pca = new PublicClientApplication(configuration);

pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    pca.setActiveAccount(event.payload.account);
  }
});
const roleBasedNavigation = (id) => {
  switch (id) {
    case 'user':
      return '/dashboard';
    case 'admin':
      return '/admin-dashboard';
    default:
      return '/';
  }
};

const PrivateRoute = ({ children }) => {
  let location = useLocation();

  const { loading } = useSelector((state) => state.login);
  const { loggedIn } = getLoggedInUser();

  if (loading) {
    return <Loader />;
  }
  if (!loggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

//test
const AuthChecker = ({ children }) => {
  let location = useLocation();
  const { loggedIn, user } = getLoggedInUser();
  const loginUserSecret = LocalStorage.getItem('NFA_user_secret') || '';
  console.log('user', user);
  let goTo =
    loginUserSecret?.success && location.state?.from?.pathname
      ? location.state?.from?.pathname
      : roleBasedNavigation(user?.user_details?.role || '');

  if (loggedIn) {
    return <Navigate to={goTo} state={{ from: location }} />;
  }

  return children;
};

function App() {
  const user = getLoggedInUser();
  const loginUserSecret = LocalStorage.getItem('NFA_user_secret') || '';
  console.log('user_App', user);
  let goTo =
    loginUserSecret?.success && location.state?.from?.pathname
      ? location.state?.from?.pathname
      : (loginUserSecret?.success || user?.success) && user?.user_details?.role
      ? roleBasedNavigation(user?.user_details?.role || '')
      : '/login';

  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path="/" element={<Navigate replace to={goTo} />} />
        <Route
          path="login"
          element={
            <AuthChecker>
              <MsalProvider instance={pca}>
                <Login />
              </MsalProvider>
            </AuthChecker>
          }
        />

        <Route
          path="forgot-password"
          element={
            <AuthChecker>
              <ForgotPassword />
            </AuthChecker>
          }
        />
        <Route
          path="change-password"
          element={
            <AuthChecker>
              <ChangePassword />
            </AuthChecker>
          }
        />
        <Route
          path="dashboard"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <DashBoard />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="initiate-envelope-1"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <InitiateEnvelope1 />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="initiate-envelope-1/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <InitiateEnvelope1 />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="create-doa"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <AdminDoaEnvelope />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="initiate-envelope-2"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <InitiateEnvelope2 />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="manage"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <AdminManage />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="dashboard1"
          element={
            <PrivateRoute>
              <ChangePassword />
            </PrivateRoute>
          }
        />
        <Route
          path="admin-dashboard"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <AdminDashboard />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="favourites"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Favourites />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="actions-required"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ActionsRequired />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="actions-required/review-actions/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ReviewActions />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="actions-required/review-actions"
          element={
            <AuthChecker>
              <MsalProvider instance={pca}>
                <UnAuthReviewActions />
              </MsalProvider>
            </AuthChecker>
          }
        />
        <Route
          path="delete-envelop"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <DeleteEnv />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Settings />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="/settings/approver"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <AdminSettings />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="/settings/designation"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <DesignationMaster />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="/settings/designations-new"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <DesignationsNew />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="/settings/designation-mapping"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <DesignationMapping />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="/settings/projects"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Projects />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="/settings/bu"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <BU />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/sbu"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <SBU />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/sbg"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <SBG />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="/settings/doa-flows"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <DoaList />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="/settings/create-doa-flow"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <CreateDOAPage />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="/settings/categories"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Categories />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="/settings/sub-categories"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <SubCategories />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="/settings/edit-doa-flow/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <EditDOAPage />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="pending"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <AdminManage1 />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="approvers"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Approver />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="rejected"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Rejected />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="rejections"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <MyRejections />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="hold"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Hold />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="forward"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Forward />
              </MsalProvider>
            </PrivateRoute>
          }
        />

        <Route
          path="information"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Information />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="cancelled"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Cancelled />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        {user?.user_details?.role ? (
          <Route
            path="final-review/:id"
            element={
              <PrivateRoute>
                <MsalProvider instance={pca}>
                  <FinalReview />
                </MsalProvider>
              </PrivateRoute>
            }
          />
        ) : (
          <Route
            path="final-review/:id"
            element={
              <AuthChecker>
                <MsalProvider instance={pca}>
                  <FinalReview />
                </MsalProvider>
              </AuthChecker>
            }
          />
        )}

        <Route
          path="*"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <PageNotFound />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <AuthChecker>
              <MsalProvider instance={pca}>
                <PageNotFound />
              </MsalProvider>
            </AuthChecker>
          }
        />
        <Route
          path="reports"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Reports />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="completed-nfa"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <CompletedNFAS />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="success"
          element={
            <AuthChecker>
              <MsalProvider instance={pca}>
                <Result />
              </MsalProvider>
            </AuthChecker>
          }
        />
      </Routes>
    </HistoryRouter>
  );
}

export default App;
